

.circle {
    width: 130px;
    height: 130px;
    border: 3px solid #F77E0B;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    font-weight: bold; 
  }
  
.defaultColor{
    color: #F77E0B;
    font-family: Poppins, sans-serif;
    font-size: 18;

}


.rectangular-input {
    width: 300px; 
    height: 50px; 
    border: 2px solid #ccc; 
    border-radius: 5px; 
    padding: 10px; 
    font-size: 16px; 
}



.screen-inner{
    padding-left: 300px;
    padding-right: 300px;
}



.rectangle {
    width: 300px; 
    height: 100px; 
    background-color: #F77E0B;
    padding: 14px; 
    font-size: 16px; 
    border-radius: 10px;
    border: 1px;
    align-items: center;
    justify-content: center;
    display: flex;

}

.number{
    color: white;
    font-weight: 800;
    font-size: 32px;
    text-align: center;
}



@media (max-width:1100px){
    .screen-inner{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}



@media (max-width:320px){
    .rectangle {
        width: 250px; 
        height: 80px; 
        background-color: #F77E0B;
        padding: 10px; 
        font-size: 16px; 
        border-radius: 10px;
        border: 1px;
        align-items: center;
        justify-content: center;
        display: flex;
    
    }

    .number{
        color: white;
        font-weight: 800;
        font-size: 22px;
        text-align: center;
    }


    .rectangular-input {
        width: 230px; 
        height: 50px; 
        border: 2px solid #ccc; 
        border-radius: 5px; 
        padding: 10px; 
        font-size: 16px; 
    }

    .defaultColor{
        color:#F77E0B;
        font-family: Poppins, sans-serif;
        font-size: 14;
        
    }

}
