.welcome-heading1 {
  color: #000;

  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
}
.welcome-heading2 {
  color: #f77e0b;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  margin-left: 12px;
}
.welcome-content {
  color: #333;

  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 170.25%; /* 37.455px */
  letter-spacing: 0.44px;
}
.welcome-content1 {
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 170.25%;
  letter-spacing: 0.44px;
}

.welcome-card {
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  height: 250px;
  margin-top: 42px;
  transform-style: preserve-3d;
  transition: transform 1.5s;
}
.welcome-card:hover {
  transform: rotateY(180deg);
}
.welcome-card-heading {
  color: #f77e0b;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-back {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f77e0b;
  color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 23.4px */
  transform: rotateY(180deg);
}
.lower_text {
  color: #333;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 170.25%; /* 37.455px */
  letter-spacing: 0.44px;
}
.upper_text {
  color: #333;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%; /* 33px */
  letter-spacing: 0.5px;
  margin-top: 40px;
}
.upper_text_color {
  color: #f77e0b;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  letter-spacing: 0.5px;
}
