.paymentscreen {
  font-family: Poppins, sans-serif;
}
.banklist-heading {
  width: 60%;
  font-size: 20px;
  font-weight: 600;
}
.getting-payment-heading {
  background-color: white;
  color: #f77e0b;
  height: 90px;
  border-radius: 10px;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.paymentscreen-inner {
  padding-left: 300px;
  padding-right: 300px;
}
.submit-button {
  background-color: #f77e0b;
  color: white;
  height: 46px;
  font-weight: 500;
  font-size: 17px;
}
.input-field {
  height: 46px;
  background-color: #e6e7eb;
  width: 100%;
}
.input-button-field {
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  cursor: pointer;
  color: #A9A9A9;
  -moz-user-select:none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.getting-payment-modal-heading {
  background-color: #f77e0b;
  color: white;
  height: 60px;
  border-radius: 10px;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.add-button {
  width: 40%;
  color: #f77e0b;
}
.button-signature {
  background-color: #f77e0b;
  color: white;
  border: 2px solid #f77e0b;
  font-size: 17px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  border-radius: 3px;
}
.sig-heading {
  font-weight: bold;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.payment-option {
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.disclaimer {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  color: #f77e0b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.disclaimer-text {
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (max-width: 1100px) {
  .paymentscreen-inner {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 70px !important;
  }
  .getting-payment-modal-heading {
    background-color: #f77e0b;
    color: white;
    height: 40px;
    border-radius: 5px;
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .banklist-heading {
    width: 65%;
    font-size: 17px;
  }
  .add-button {
    width: 35%;
  }
}
@media (max-width: 450px) {
  .banklist-heading {
    width: 72%;
  }
  .add-button {
    width: 28%;
  }
}
