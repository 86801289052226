.avatar-container {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid rgb(174, 170, 170);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px 20px;
}
.long-text {
  word-wrap: break-word;
}
.avatar-image {
  width: 97%;
  height: 97%;
  border-radius: 50%;
  object-fit: cover;
}
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  font-family: Poppins, sans-serif;
  padding-left: 20px;
}
.title-name {
  font-weight: 600;
  font-family: Poppins, sans-serif;
}
.type-tag {
  height: 25px;
  border-radius: 15px;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 15px;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
}
.first-message {
  font-size: 14px;
  color: grey;
}
.chat-divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.unread {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f77e0b;
  height: 20px;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  margin-right: 15px;
  padding: 10px;
}
.chat-body-header {
  width: 100%;
  height: 11vh;
  background-color: white;
  cursor: pointer;
  z-index: 100;
}
@media (max-width: 767px) {
  .title {
    min-width: 200px;
  }
  .title-name {
    font-weight: bold;
    font-size: 12px !important;
  }
  .first-message {
    font-size: 12px;
    color: grey;
  }
  .type-tag {
    height: 18px !important;
    border-radius: 10px;
    color: white;
    padding-left: 3px !important;
    padding-right: 3px !important;
    font-size: 12px !important;
    margin-left: 8px !important;
  }
}

.input-label {
  font-family: Poppins, sans-serif;
  height: 70px;
  width: 100%;
}
textarea {
  resize: none;
}
.input-input {
  border-radius: 10px;
  min-height: 50px;
  height: auto;
  padding: 10px;
  border: none;
}
.input-class {
  flex: 1;
}
.input-class input:focus {
  outline: none;
}
.input-div-area {
  background-color: #dddddd;
  height: 9vh;
  padding-right: 30px;
}
.basic-loading {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}
.loading-padding {
  padding-left: 20px;
  padding-right: 10px;
}
.file-upload {
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}
.icon-style {
  padding: 10px;
}
.chat-declined-text {
  font-weight: bold;
  font-size: 20px;
  padding-top: 17px;
  color: #7070708e;
}
.recording-text {
  width: 50px;
  padding-left: 2px;
}
.chat-search-input {
  height: 48px;
  width: 92%;
  border-radius: 10px;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 10px;
}
