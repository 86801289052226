.feeds-img-div {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .feeds-img-div {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }
}
@media (max-width: 350px) {
  .feeds-img-div {
    width: 100%;
    height: 250px;
    border-radius: 10px;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s 1;
}
.button-send {
  background-color: #f77e0b;
  color: white;
  font-weight: bold;
  border-radius: 15px;
  height: 35px;
  margin-top: 15px;
}
.comment-input {
  border: 1px solid rgb(91, 90, 90);
  width: 90%;
  height: 46px;
  border-radius: 25px;
  margin-top: 20px;
  padding: 20px;
}
