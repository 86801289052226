.contract-heading {
  border-bottom: 2px solid #f77e0b;
  height: 70px;
  margin-top: 20px;
  width: 100%;
  overflow-x: scroll;
}
.contract-heading-inner {
  min-width: 400px;
}
.selected-tab-style {
  color: white;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  background-color: #f77e0b;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}
.un-selected-tab-style {
  cursor: pointer;
  color: #f77e0b;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}
