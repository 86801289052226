.paymentscreen{
    font-family:Poppins, sans-serif;
}
.paymentscreen-inner{
    padding-left: 300px;
    padding-right: 300px;
}
.size{
    font-size:100%
}

@media (max-width:1100px){
    .paymentscreen-inner{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .size{
        font-size:80%
    }
    
}
