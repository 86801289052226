.contract-card {
  border: 1px solid rgb(232, 229, 229);
  width: 100%;
  height: auto;
  padding: 10px;
  font-family: Poppins, sans-serif;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 10px 10px 10px rgb(232, 229, 229);
  font-size: 15px;
}
.contract-profile {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid rgb(174, 170, 170);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.avatar-image {
  width: 97%;
  height: 97%;
  border-radius: 50%;
  object-fit: cover;
}
.status-div {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f77e0b;
  color: white;
  border-radius: 12px;
}
.userType-div {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-radius: 12px;
}

.skill-div {
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(138, 187, 42);
  color: white;
  border-radius: 12px;
}
@media (max-width: 768px) {
  .contract-card {
    font-size: 11px !important;
  }
  .contract-profile {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgb(174, 170, 170);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
}

.contract-card .row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.date-div {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px;
  box-shadow: 5px 5px 5px 5px rgb(193, 192, 192);
  border-radius: 15px;
  font-family: Poppins, sans-serif;
}
.date-divider {
  height: 100px;
  width: 1px;
  border: 1px solid rgb(222, 220, 220);
}

.contract-images {
  width: 100px;
  height: 100px;
  position: relative;
}
.ant-input {
  background-color: #e6e7eb !important;
}
.ant-input-number-handler {
  display: none !important;
  background-color: #e6e7eb !important;
}
.ant-input-number-handler-wrap {
  width: 0 !important;
}
.ant-upload.ant-upload-select {
  border: 2px solid #f77e0b !important;
  width: 100px !important;
}
.crossIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  border: 2px solid #f77e0b;
  cursor: pointer;
  font-size: 15px;
  color: #f77e0b;
  background-color: white;
  border-radius: 50%;
}
.ant-tooltip {
  visibility: hidden !important;
}
.anticon-eye {
  display: none !important;
}
.ant-form-item .ant-form-item-label > label {
  font-family: Poppins, sans-serif !important;
}
.contractButtonText1 {
  height: 56px;
  color: white;
  background-color: #f77e0b;
  border: 1px solid #f77e0b;
}
.contractButtonText2 {
  height: 56px;
  font-size: 18px;
  background-color: #f77e0b;
  color: white;
}
.cancelContractButton {
  height: 56px;
  color: white;
  background-color: #ff3b30ff;
  border: 1px solid #ff3b30ff;
}
.acceptContractBtn {
  background-color: #F77e0b;
  border: 2px solid #F77e0b;
  height: 56px;
  width: 100px;
  color: white;
}
.acceptContractBtnWhite {
  background-color: #FFFFFF;
  border: 2px solid #F77e0b;
  height: 56px;
  width: 100px;
  color: #F77e0b;
}
