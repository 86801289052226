.profile-videos-div {
  width: 100%;
  height: 310px;
  margin-bottom: 30px;
  overflow-x: scroll;
  padding: 25px;
  display: flex;
  border-radius: 15px;
}
.profile-video-card {
  min-width: 200px;
  max-width: 200px;
  height: 250px;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
}
.video-card-profile {
  width: 400px;
  height: 600px;
  border-radius: 20px;
  margin: 70px;
}
@media (max-width: 330px) {
  .profile-videos-div {
    width: 280px !important;
  }
}
.video-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
