.how-to-card {
  width: 600px;
  height: 600px;
  box-shadow: 0px 4px 10px rgb(248, 145, 19);
  margin-top: 40px;
  border-radius: 20px;
  cursor: pointer;
}
.video-img {
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.title-video {
  font-family: Poppins, sans-serif;
  font-size: 20px;
}
@media (max-width: 767px) {
  .how-to-card {
    width: 400px;
    height: 400px;
  }
  .title-video {
    font-family: Poppins, sans-serif;
    font-size: 15px !important;
  }
}
@media (max-width: 400px) {
  .how-to-card {
    width: 300px;
    height: 300px;
  }
}
