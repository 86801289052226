.bank-account-card {
  width: 100%;
  height: auto;
  border: 3px solid #f77e0b;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}
.deactivate {
  background-color: #f77e0b;
  border-radius: 20px;
  color: white;
  border: 1px solid #f77e0b;
}
.disclaimer1 {
  font-size: 17px;
  font-family: Poppins, sans-serif;
}
@media (max-width: 767px) {
  .bank-account-card {
    border: 2px solid #f77e0b;
    border-radius: 10px;
    padding: 5px;
  }
}

.buttonText {
  background-color: #f77e0b;
  color: white;
  font-size: 17px;
  font-family: Poppins, sans-serif;
  border: none;
  border-radius: 3px;
}
.modal-text {
  font-size: 20px;
  font-family: Poppins, sans-serif;
  text-align: center;
}
.button-modal {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  color: white;
  background-color: #f77e0b;
  width: 35%;
  height: 50px;
  font-weight: bold;
}
