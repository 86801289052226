.current-location {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 15%;
  right: 1%;
  border-radius: 5px;
}
.map-marker {
  width: 94px;
  height: 92px;
  margin-top: -94px;
  margin-left: -47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contractor-image {
  width: 70px;
  height: 70px;
  border: 2px solid #f77e0b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gigster-image {
  width: 70px;
  height: 70px;
  border: 2px solid #8abb2a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contractor-tag {
  width: 90%;
  height: 20px;
  border-radius: 12px;
  background-color: #f77e0b;
  color: white;
  margin-top: -10px;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gigster-tag {
  width: 80%;
  height: 20px;
  border-radius: 12px;
  background-color: #8abb2a;
  color: white;
  margin-top: -10px;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contractor-triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #f77e0b;
}

.gigster-triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #8abb2a;
}
.button-map {
  position: absolute;
  top: 50px;
  left: 1%;
  color: #f77e0b;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
}
