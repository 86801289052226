.settings-text {
  font-family: Poppins, sans-serif;
  font-size: 17px;
}

.activate-deactivate {
  border: 3px solid #f77e0b;
  border-radius: 10px;
  height: 70px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f77e0b;
  font-weight: bold;
  cursor: pointer;
}
.signout-button {
  background-color: #f77e0b;
  border-radius: 10px;
  height: 70px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.change_heading {
  font-family: Poppins, sans-serif;
  font-size: 23px;
  font-weight: bold;
}
.button-submit {
  width: 300px;
  background-color: #f77e0b;
  height: 56px;
  color: white;
}
