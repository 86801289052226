/* @import url(" https");  */
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.et_builder_inner_content { 
    position: relative; 
    z-index: 1;
} 

article { 
    display: block;
} 

#main-content { 
    background-color: #fff;
} 

.page.et_pb_pagebuilder_layout #main-content { 
    background-color: transparent;
} 

.et_fixed_nav.et_show_nav #page-container { 
    padding-top: 80px;
} 

.et_fixed_nav.et_show_nav.et_secondary_nav_enabled #page-container { 
    padding-top: 111px;
} 

@media all{ 
  body { 
    margin: 0; 
    padding: 0;
  } 
}     

body { 
    margin:   0; 
    padding:   0; 
    border:   0; 
    outline:   0; 
    font-size:  14px; 
    -ms-text-size-adjust:   100%; 
    -webkit-text-size-adjust:   100%; 
    vertical-align:   baseline; 
    background:   transparent;
    line-height:  1.7em; 
    font-family: Open Sans,Arial,sans-serif; 
    color: #666; 
    background-color: #fff; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

body.et_cover_background { 
    background-size: cover!important; 
    background-position: top!important; 
    background-repeat: no-repeat!important; 
    background-attachment: fixed; 
    background-repeat-x: no-repeat !important; 
    background-repeat-y: no-repeat !important;
} 

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.et_pb_section { 
    position: relative; 
    background-color: #fff; 
    background-position: 50%; 
    background-size: cover;
} 

@media (min-width: 981px){ 
  .et_pb_section { 
    padding: 4% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .et_pb_section { 
    padding: 54px 0;
  } 
}     

.et_pb_section_0.et_pb_section { 
    padding-bottom: 5px;
} 

.et_pb_row { 
    width:  80%; 
    max-width:  1080px; 
    margin:  auto; 
    position:  relative;
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

@media (min-width: 981px){ 
  .et_pb_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .et_pb_row { 
    padding: 27px 0;
  } 
}     

.et_pb_row:after,.et_pb_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.et_pb_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.et_pb_column { 
    float:  left; 
    background-size:  cover; 
    background-position:  50%; 
    position:  relative; 
    z-index:  2; 
    min-height:  1px;
    background-repeat: no-repeat; 
    background-repeat-x: no-repeat; 
    background-repeat-y: no-repeat;
} 

@media (min-width: 981px){ 
  .et_pb_column  { 
    margin-right: 5.5%;
  } 

  .et_pb_column_4_4  { 
    width: 100%;
  } 
}     

.et_pb_row .et_pb_column.et-last-child ,.et_pb_row .et_pb_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .et_pb_row .et_pb_column.et-last-child ,.et_pb_row .et_pb_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.et_pb_module { 
    background-size:  cover; 
    background-position:  50%; 
    background-repeat:  no-repeat; 
    background-repeat-x:  no-repeat; 
    background-repeat-y:  no-repeat;
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.et_pb_text { 
    word-wrap: break-word;
} 

.et_pb_module.et_pb_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .et_pb_column_4_4 .et_pb_module  { 
    margin-bottom: 2.75%;
  } 

  div.et_pb_row .et_pb_column .et_pb_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.et_pb_text_inner { 
    position: relative;
} 

.et_pb_text > :last-child  { 
    padding-bottom: 0;
} 

/* p { 
    margin:  0; 
    padding:  0; 
    border:  0; 
    outline:  0; 
    font-size:  100%; 
    -ms-text-size-adjust:  100%; 
    -webkit-text-size-adjust:  100%; 
    vertical-align:  baseline; 
    background:  transparent;
    padding-bottom: 1em;
}  */

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

span { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

b { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

u { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    margin:    0; 
    padding:    0; 
    border:    0; 
    outline:    0; 
    font-size:    100%; 
    -ms-text-size-adjust:    100%; 
    -webkit-text-size-adjust:    100%; 
    vertical-align:    baseline; 
    background:    transparent;
    color:  #8ec549;
    text-decoration:  none;
} 

a,a:hover { 
    text-decoration: none;
} 


