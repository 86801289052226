.container-div{
    height:400px;
    width:50%;
    border-radius: 15px;
    margin-top: 20px;
}
.backButton{
    margin-left: 30%;
}
.top-bar{
    width:50%;
    padding-left: 30px;
}
@media(max-width:1650px){
    .backButton{
        margin-left: 27% !important;
    }

}
@media(max-width:1250px){
    .backButton{
        margin-left: 22% !important;
    }

}
@media(max-width:1000px){
    .backButton{
        margin-left: 17% !important;
    }

}


@media (max-width: 768px){
    .container-div{
        height: 500px !important;
        width: 75% !important;

    }
    .top-bar{
        width:75% !important;
        padding-left: 0px !important;
    }
    .backButton{
        margin-left: 22% !important;
    }
    
    @media(max-width:650px){
        .backButton{
            margin-left: 17% !important;
        }
    
    }
    
}
@media (max-width: 450px){
    .container-div{
        height: 500px !important;
        width: 90% !important;
        
    }
    .top-bar{
        width:90% !important;
    }
    .backButton{
        margin-left: 15% !important;
    }


}