.amount-card{
    background: linear-gradient(to right, rgba(247, 126, 11, 1),rgba(240, 177, 117, 1));
    width:100%;
    height:200px;
    border-radius: 10px ;
    margin-top: 20px;
    font-family: Poppins, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.transaction-card{
    width:100%;
    height:auto;
    border:3px solid #F77E0B;
    border-radius:10px;
    padding: 15px;
    margin-top: 20px;
}
.divider{
    height:3px;
    width:100%;
    background-color: #F77E0B;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 10px;
}
@media (max-width:767px){
    .transaction-card{
        border:2px solid #F77E0B;
        border-radius:10px;
        padding: 5px;
    }
    
}