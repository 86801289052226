.subscription-heading {
  color: #f77e0b;
  font-family: Poppins, sans-serif;
  text-decoration: underline;
  font-size: 30px;
  font-weight: bold;
}
.subscription-heading2 {
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
}
.subscription-header {
  height: 100px;
}
.subscription-card {
  width: 350px;
  height: auto;
  display: inline-flex;
  padding: 35px 25px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid #f77e0b;
  background: #fff;
  box-shadow: 0px 4px 25px 2px rgba(0, 0, 0, 0.05);
  margin-top: 50px;
}
.sub-divider {
  width: 100%;
  height: 2px;
  background-color: #f77e0b;
  margin-top: 5px;
}
.sub-item {
  width: 100%;
  margin-top: 15px;
  font-family: Poppins, sans-serif;
}
.sub-button {
  width: 100%;
  height: 56px;
  border: 2px solid #f77e0b;
  background-color: #f77e0b;
  color: white;
  font-family: Poppins, sans-serif;
}
@media (max-width: 768px) {
  .subscription-card {
    width: 250px !important;
    margin-top: 10px !important;
    border: 1px solid #f77e0b;
    margin-bottom: 30px;
  }
}
