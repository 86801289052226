.amount-card{
    background: linear-gradient(to right, rgba(247, 126, 11, 1),rgba(240, 177, 117, 1));
    width:100%;
    height:200px;
    border-radius: 10px ;
    margin-top: 20px;
    font-family: Poppins, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.in-out{
    border-radius: 10px;
    font-family: Poppins, sans-serif;
}
.in-icon{
    width:20px;
    margin-left: 5px;
}
