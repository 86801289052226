.main-video-uploader {
  height: 400px;
}
.sub-video-uploader {
  height: 50%;
}
.sub-video-uploader-two {
  height: 165px;
}

.sub-video-uploader-wrapper {
  padding-left: 20px;
  padding-bottom: 32px;
}
.imageUploader {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.imageUploader > input {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}
.imageUploader > labelP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.preview {
  position: absolute;
  width: 100%;
  height: 75%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #f77e0b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.proofPreviewer {
  position: absolute;
  width: 100%;
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.container-div {
  height: 100%;
  padding: 20px;
}
@media (max-width: 786px) {
  .main-video-uploader {
    height: 150px !important;
  }
  .sub-video-uploader-wrapper {
    padding-left: 10px;
    padding-bottom: 20px;
  }
  .sub-video-uploader-two {
    height: 70px;
  }
}

.proofPreviewer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  display: block !important;
}

.proofPreviewer .react-thumbnail-generator {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px !important;
  font-family: Poppins, sans-serif;
  margin-left: 18px;
  display: block;
}
.upload-video-text {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
}
.upload-video-sub-text {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
}

.upload-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
