
.health-show{
    font-size: 30px;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    color:#F77E0B

}

.username-text{
    font-size: 20px;
    font-family: Poppins, sans-serif;
    font-weight: bold;
}
.username-subtext{
    font-size: 15px;
    font-family: Poppins, sans-serif;
}

.store-icons{
    width: 100%;
    gap: 18px;
    display: flex;
    justify-content: space-between;

}
.search-input{
    height: 48px;
    width: 100%;
    border-radius: 10px;
}
  .search-button{
    height: 48px;
    color: white;
    background-color: #F77E0B;
    margin-left: 10px;
  }
  .bank-modal-text{
    font-size: 15px;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    margin-top: 10px;
  }
  .bank-modal-image{
    width:60px;
    cursor: pointer;
  }