.mainModalDiv {
  position: fixed;
  top: 10vh;
  z-index: 100;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(211, 206, 206, 0.5);
  right: 0px;
}

.closeIcon {
  position: absolute;
  top: 50px;
  right: 50px;
  color: #f77e0b;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.storyChangeIcons {
  width: 70px;
  margin-right: 10px;
  cursor: pointer;
}
.play-right-icon {
  margin-left: 10px;
}
.deleteStoryIcon {
  width: 20px;
  cursor: pointer;
}

.loading-outline {
  font-size: 44px;
  color: #f77e0b;
}

.delete-icon-div {
  position: absolute;
  top: 50px;
}

.delete-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.app-logo {
  width: 100%;
  height: 70px;
}

.profile-story-image {
  position: absolute;
  object-fit: cover;
}

@media (max-width: 768px) {
  .storyChangeIcons {
    width: 40px !important ;
    margin-right: 10px;
    cursor: pointer;
  }
}
