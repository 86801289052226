.click_heading {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;
    color: #333333;
    line-height: 2;


}

.click_sub_heading {
    font-weight: 300;
    font-size: 20px;
    color: #333333;
    font-family: Poppins, sans-serif;
}
.joinUs{
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 25px;
    color: #333333;

}
.separator{
    border-bottom: 1px solid #E6E7EB;
    margin-bottom: 20px;
}

.back-button{
    position: absolute;
      top: 100px ;
      left: 40px;
      font-family: Poppins, sans-serif;
}

@media (max-width: 768px) {
    .click_heading {
        font-size: 20px !important;
    }

    .click_sub_heading {
        font-size: 15px !important;
    }


}
@media (max-width: 420px) {
    .click_heading {
        font-size: 16px ! important;
    }

    .click_sub_heading {
        font-size: 13px !important;
    }
    .joinUs{
        font-size: 20px !important;

    }


}
@media (max-width: 350px) {
    .click_heading {
        font-size: 14px !important;
    }

    .click_sub_heading {
        font-size: 12px !important;
    }
    .joinUs{
        font-size: 18px !important;

    }



}
