.stories-container-div {
  height: 300px;
}

.stories-container-inner-div {
  margin-right: 20px;
}

.plus-icon {
  width: 30px;
  height: 30px;
  font-size: 40px;
  font-weight: bold;
  color: #f77e0b;
  cursor: pointer;
}

.story-card-div {
  overflow-x: scroll;
}
.story-card {
  width: 200px;
  height: 250px;
  border: 2px solid #f77e0b;
  border-radius: 11px;
  background: linear-gradient(
      180deg,
      rgba(250, 235, 235, 0) 0%,
      rgba(0, 0, 0, 0.2) 174.45%
    ),
    #f5f5f5;
}
.story-card-bg {
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(141, 141, 141, 0.05);
  backdrop-filter: blur(45.92533493041992px);
}
.gig-circle {
  width: 80px;
  height: 80px;
  border: 2px solid #f77e0b;
  border-radius: 50%;
}

.gig-image-alt {
  object-fit: cover;
}
.gig-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
::-webkit-scrollbar {
  display: none;
}
@media (max-width: 100px) {
  .gig-circle {
    width: 130px !important;
  }
}

.story-name-text {
  font-family: Poppins, sans-serif;
}

.story-name {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
}
.story-logo {
  width: 80px;
}
.create-story {
  font-family: Poppins, sans-serif;
  font-weight: bold;
}

.video-gallery-div {
  cursor: pointer;
}

.gallery-icon {
  padding: 10px;
  background-color: #f77e0b;
  border-radius: 5px;
  margin-top: 5px;
}

.hide-input {
  display: none;
}

@media (max-width: 767px) {
  .story-card {
    width: 100px !important;
    height: 150px !important;
    border: 2px solid #f77e0b;
    border-radius: 11px;
    background: linear-gradient(
        180deg,
        rgba(250, 235, 235, 0) 0%,
        rgba(0, 0, 0, 0.2) 174.45%
      ),
      #f5f5f5;
  }
  .gig-circle {
    width: 50px;
    height: 50px;
    border: 2px solid #f77e0b;
    border-radius: 50%;
  }
  .story-logo {
    width: 50px !important;
  }
  .story-name {
    margin-top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold !important;
    font-size: 14px !important;
  }
  .create-story {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: bold;
  }

  #fileInput {
    display: none;
  }

  .file-upload-btn {
    align-items: center;
    padding: 10px;
    border: none;
    background-color: #3498db;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  .file-upload-btn i {
    margin-right: 5px;
  }
}

/* stories */

.upload-video {
  height: 400px;
  min-width: 250px;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 1px 1px 10px 1px rgb(129, 127, 127, 0.5);
}

.upload-card {
  padding: 50px;
  margin-top: 50px;
}
.upload-div {
  overflow-x: scroll;
  min-width: 300px;
  height: 450px;
  padding: 15px;
  display: flex;
}

.upload-div .ant-upload.ant-upload-select {
  height: 400px !important;
  min-width: 250px !important;
  border-radius: 20px !important;
  margin: 10px !important;
  box-shadow: 1px 1px 10px 1px rgb(129, 127, 127, 0.5) !important;
  border: none !important;
  font-family: Poppins, sans-serif;
}

.video-thumbnail-setter {
  position: relative;
}

.thumbnail-preview,
.react-thumbnail-generator {
  width: 100px;
  height: 150px;
  margin-right: 20px;
}
.video-thumbnail-div {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: scroll;
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}
.video-thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px !important;
}
/* .react-thumbnail-generator img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.upload-div .react-thumbnail-generator {
  height: 400px !important;
  min-width: 250px !important;
  border-radius: 20px !important;
  margin: 10px !important;
  box-shadow: 1px 1px 10px 1px rgb(129, 127, 127, 0.5) !important;
  border: none !important;
  font-family: Poppins, sans-serif;
}
.upload-div .react-thumbnail-generator img {
  border-radius: 20px !important;
}
 */
.close-icon-outlined {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.cross-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  background-color: red;
  border: 1px solid white;
  border-radius: 50%;
  font-weight: bold;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video-upload-button {
  width: 100%;
  height: 56px;
  background-color: #f77e0b;
  color: white;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  margin-top: 30px;
  font-size: 17px;
}

.gig-preview {
  position: relative;
  height: 400px;
  width: 250px;
  max-height: 400px !important;
  min-width: 250px !important;
  border-radius: 20px !important;
  margin: 10px !important;
  box-shadow: 1px 1px 10px 1px rgb(129, 127, 127, 0.5) !important;
  border: none !important;
  font-family: Poppins, sans-serif;
}

.gig-preview-image {
  object-fit: cover;
  width: 100%;
  height: 100%;

  border-radius: 20px !important;
}

.main-wrapper-div {
  position: relative;
  width: 400px;
  height: 600px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 10px;
}
.absolute-text-div {
  top: 45%;
  position: absolute;
  color: white;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(90, 90, 90, 0.43);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  font-size: 30px;
  font-family: Poppins, sans-serif;
}
.absolute-text-icon {
  position: absolute;
  top: 30px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  right: 20px;
  z-index: 100;
}
.glassInput {
  color: white;
  width: 100%;
  height: auto;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(90, 90, 90, 0.43);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  font-size: 30px;
  font-family: Poppins, sans-serif;
  border: none;
  z-index: 1000;
  outline: none;
  resize: none;
  padding: 5px;
  flex-wrap: wrap;
}

.modal-content {
  margin-top: 10px;
}
.button-continue {
  width: 100%;
  height: 46px;
  background-color: #f77e0b;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  margin-top: 20px;
}
.upload-story-class {
  width: 250px;
  height: 400px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}
.typing-wrapper-div {
  position: absolute;
  width: 400px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.typing-wrapper-div2 {
  top: 0;
  position: absolute;
  width: 250px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
