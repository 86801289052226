.feed-divider {
  background-color: white;
  height: 6px;
  width: 100%;
}
.feed-divider2 {
  background-color: rgb(194, 192, 192);
  height: 2px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.create-post-button {
  background-color: #e6e7eb;
  width: 75%;
  height: 50px;
  border-radius: 5px;
  color: rgb(158, 156, 156);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Poppins, sans-serif;
  padding-left: 20px;
}
.feeds-name {
  font-family: Poppins, sans-serif;
  font-size: 15px;
}
.feeds-name-subheading {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: rgb(158, 156, 156);
}
.feeds-card-text {
  word-wrap: break-word;
}
.feeds-card-content {
  margin-left: 5%;
  margin-right: 5%;
  font-family: Poppins, sans-serif;
}
