.notification-box {
  background-color: white;
  font-family: Poppins, sans-serif;
  height: 500px;
  width: 400px;
}
.notification-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f77e0b;
  height: 40px;
  color: white;
  font-family: Poppins, sans-serif;
  position: "relative";
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 550;
}
.clear-all {
  position: absolute;
  margin-left: 300px;
  font-size: 13px;
  font-weight: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .notification-box {
    height: 400px !important;
    width: 300px !important;
  }
  .clear-all {
    margin-left: 200px !important;
    font-size: 12px;
  }
}

.notification-message-div {
  height: 450px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  color: black;
  padding: 5px;
  margin-bottom: 50px;
}

.notification-message {
  height: 90px;
  border-bottom: 1px solid rgb(213, 213, 213);
  display: flex;
  align-items: center;
}
.notification-type-tag {
  height: 18px;
  border-radius: 15px;
  color: white;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
}

.notification-date {
  height: 20px;
}
.notification-item {
  width: 380px;
}

@media (max-width: 767px) {
  .notification-item {
    width: 280px !important;
  }
}
