.signUp-container{
    width:30%;
    border-radius: 15px;
    margin-top: 20px;
}
.top-bar{
    position:relative;
    justify-content: center;
    width:30% !important;
    padding-left: 30px;
}
.backButton1{
    position:absolute;
    display: flex;
    align-items: center;
    margin-left: 20px;
    color:black
}
@media (max-width: 1400px){
    .signUp-container{
        width: 45% !important;

    }
    .top-bar{
        width:40% !important;
        padding-left: 20px !important;
    }


}
@media (max-width: 1000px){
    .signUp-container{
        width: 55% !important;

    }
    .top-bar{
        width:50% !important;
        padding-left: 20px !important;
    }

}

@media (max-width: 768px){
    .signUp-container{
        width: 80% !important;

    }
    .top-bar{
        width:75% !important;
        padding-left: 0px !important;
    }
    .backButton1{
        margin-left: 0px !important;
    }    
}
@media (max-width: 450px){
    .signUp-container{
        width: 95% !important;
        
    }
    .top-bar{
        width:90% !important;
    }


}

.ant-radio-inner::after{
    background-color: #F77E0B !important;
}

.ant-radio-inner{
    background-color: white !important;
}
