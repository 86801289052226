.navigator {
  position: fixed;
  top: 50%;
  left: 0;
  width: 80px; /* Adjust the width to your preference */
  height: 100%; /* Make it take the full height of the viewport */
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.nav-icon {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f77e0b;
}
.red-dot-style {
  display: flex;
  padding: 4px;
}

@media (max-width: 767px) {
  .navigator {
    position: fixed;
    top: 90% !important;
    width: 100%;
    height: 70px;
    z-index: 1;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .nav-icon {
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
  }
}
